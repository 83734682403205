import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Button from "@/components/Button";

import RejectedIcon from "@/assets/icons/rejected.svg?react";

interface RejectionMessageProps {
  rejectionReason?: string | null;
  aspectRatio?: number;
}

const RejectionMessage = ({
  rejectionReason,
  aspectRatio = 1,
}: RejectionMessageProps) => {
  const [showRejectedContent, setShowRejectedContent] = useState(false);

  return (
    <AnimatePresence>
      {rejectionReason && !showRejectedContent && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="absolute rounded-lg -inset-[1%] z-10 size-[102%] flex items-center justify-center bg-white/60 dark:bg-[#232323]/60 backdrop-blur-[20px]"
        >
          <div className="text-xs max-w-[159px] text-center truncate">
            <RejectedIcon className="text-error-default dark:text-error-dark-default mx-auto mb-[14px]" />
            <p className="font-medium max-sm:line-clamp-1 whitespace-pre-wrap mb-[5px] line-clamp-3">
              {rejectionReason}
            </p>
            {aspectRatio < 1.5 && (
              <p className="text-[#686868] dark:text-[#A4A4A4] whitespace-pre-wrap mb-[20px] leading-[18px]">
                Violates our terms and conditions
              </p>
            )}
            <Button
              variant="link"
              className="font-bold text-sm"
              onClick={() => setShowRejectedContent(true)}
            >
              Show
            </Button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default RejectionMessage;
