import { cn } from "@/utils/utils";
import { useAppSelector } from "@/redux/hooks";
import { userSelector } from "@/redux/App";
import { useRequest } from "@/hooks/useRequest";
import { useQueryClient } from "@tanstack/react-query";
import { generatePfpFileName } from "@/features/CroppableUploader/utils/helpers";
import { User } from "@/shared/types/user";
import DefaultAvatar from "@/components/Avatar/DefaultAvatar";
import PfpUpload from "@/components/Header/UserDropdown/PfpUpload";
import toast from "react-hot-toast";

interface AvatarProps {
  expanded?: boolean;
  src?: string | null;
  className?: string;
  avatarClassName?: string;
  bigPen?: boolean;
}

const Avatar = ({
  expanded,
  src,
  className,
  avatarClassName,
  bigPen,
}: AvatarProps) => {
  const { sendRequest, loading } = useRequest();
  const queryClient = useQueryClient();
  const userId = useAppSelector(userSelector).id;
  const handlePfpUpload = async (image: File) => {
    const formData = new FormData();
    formData.append("file", image, generatePfpFileName());

    try {
      await sendRequest({
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        url: "Users",
        data: formData,
      });
      toast.success("Your profile picture has been updated!");
      queryClient.setQueryData(["dashboard", { userId }], (oldData: User) => {
        return {
          ...oldData,
          profilePicture: URL.createObjectURL(image),
        };
      });
      queryClient.invalidateQueries({
        queryKey: ["dashboard", { userId }],
      });
    } catch (error) {
      toast.error("Sorry, your profile picture could not be updated.");
    }
  };

  return (
    <div className={cn("relative shrink-0", className)}>
      <DefaultAvatar
        src={src}
        className={cn(
          "mx-0 border-none shrink-0",
          expanded ? "size-[44px]" : "size-[38px]",
          avatarClassName
        )}
      />
      {expanded && (
        <PfpUpload
          loading={loading}
          onUpload={handlePfpUpload}
          buttonClassName={cn(bigPen ? "!size-[32px]" : "!size-[20px]")}
          iconClassName={cn(bigPen ? "!size-[12px]" : "!size-[8px]")}
          containerClassName={cn(
            "absolute",
            bigPen ? "-end-[4px] bottom-[3px]" : "-bottom-[2px] -end-[2px]"
          )}
        />
      )}
    </div>
  );
};

export default Avatar;
