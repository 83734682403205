import Button from "./Button";
import { cn } from "@/utils/utils";
import { Link } from "react-router";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { useAppDispatch } from "@/redux/hooks";
import { showBulkUploadPopup } from "@/redux/App";
import NewBadge from "./NewBadge";

import PlusIcon from "@/assets/icons/plus.svg?react";

interface UploadButtonProps {
  buttonClassName?: string;
  muted?: boolean;
}

const UploadButton = ({ buttonClassName, muted }: UploadButtonProps) => {
  const dispatch = useAppDispatch();

  return (
    <Popover>
      {({ open }) => (
        <>
          <PopoverButton
            as={Button}
            variant={muted ? "default-dimmed" : "default"}
            icon={muted ? undefined : PlusIcon}
            iconClassName={cn("transition-transform duration-200 size-[14px]", {
              "rotate-45": open,
            })}
            className={cn(
              {
                "-tracking-[0.28px] !gap-[8px] !py-[10.5px] !text-sm !px-[14.5px] !leading-[21px]":
                  !muted,
              },
              buttonClassName
            )}
          >
            {muted ? "Upload" : "Upload content"}
          </PopoverButton>

          <Transition
            enter="duration-200 ease-out"
            enterFrom="scale-95 opacity-0"
            enterTo="scale-100 opacity-100"
            leave="duration-300 ease-out"
            leaveFrom="scale-100 opacity-100"
            leaveTo="scale-95 opacity-0"
          >
            <PopoverPanel
              anchor="bottom start"
              className="p-5 z-40 bg-white dark:bg-tertiary-950 rounded-xl shadow-[0px_4px_20px_0px_#A4A2A219] dark:shadow-none origin-top transition [--anchor-gap:12px] w-[197px] ps-[28px] pe-[19px] pt-[22px] pb-[23.35px]"
            >
              <ul className="space-y-[21px]">
                <li>
                  <Link
                    to="/upload"
                    className="unstyled-link text-sm hover:underline"
                  >
                    Upload
                  </Link>
                </li>
                <li>
                  <Button
                    className="text-sm hover:underline flex items-center gap-[12px] !font-normal !overflow-visible"
                    variant="link-unstyled"
                    onClick={() => dispatch(showBulkUploadPopup(true))}
                  >
                    <span className="shrink-0">Bulk upload</span>
                    <NewBadge />
                  </Button>
                </li>
              </ul>
            </PopoverPanel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default UploadButton;
