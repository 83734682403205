import { useRequest } from "@/hooks/useRequest";
import { useNavigate } from "react-router";
import { useQueryClient } from "@tanstack/react-query";
import IconWShadow from "@/components/IconWShadow";
import Modal from "@/components/Modal";
import toast from "react-hot-toast";
import Button from "@/components/Button";

import TrashIcon from "@/assets/icons/trash.svg?react";

interface ConfirmDeleteProps {
  open: boolean;
  onClose: () => void;
  id: string;
}

const ConfirmDelete = ({ open, id, onClose }: ConfirmDeleteProps) => {
  const navigate = useNavigate();
  const { sendRequest, loading } = useRequest();
  const queryClient = useQueryClient();

  const handleDelete = async () => {
    try {
      await sendRequest({
        method: "DELETE",
        url: `Collection/DeleteCollection/${id}`,
      });
      queryClient.refetchQueries({
        queryKey: ["collections"],
      });
      onClose();
      toast.success("Collection deleted successfully");
      navigate("/creator-dashboard/collections");
    } catch (error) {
      toast.error(
        "Sorry, your collection could not be deleted. Please try again later."
      );
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className="w-[20rem] h-[24.563rem] sm:w-[28.875rem] sm:h-[24rem] flex flex-col items-center sm:p-0 !pt-[72px]"
    >
      <IconWShadow className="mb-5" color="red">
        <TrashIcon className="text-white size-[1.5rem]" />
      </IconWShadow>
      <div className="text-center flex flex-col gap-2 sm:gap-3 items-center max-sm:w-full">
        <h4 className="font-semibold">Delete collection</h4>
        <p className="text-xs sm:text-sm text-tertiary md:max-w-[70%]">
          Are you sure you want to delete this collection permanently?
        </p>
        <div className="flex max-sm:flex-col items-center mt-7 max-sm:w-full">
          <Button
            className="max-sm:w-full sm:!ps-2 sm:pe-5 max-sm:order-2 after:!hidden hover:!text-black dark:hover:!text-white"
            onClick={onClose}
            variant="transparent"
          >
            Back
          </Button>
          <Button
            className="max-sm:!w-full sm:w-[10.813rem] h-[3.25rem]"
            onClick={handleDelete}
            disabled={loading}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDelete;
