import { useRequest } from "@/hooks/useRequest";
import { useQueryClient } from "@tanstack/react-query";
import { useAppSelector } from "@/redux/hooks";
import { userSelector } from "@/redux/App";
import { Collection } from "@/shared/types/collection";
import { cn } from "@/utils/utils";
import PlusIcon from "@/assets/icons/plus.svg?react";
import Button from "@/components/Button";
import toast from "react-hot-toast";
import Thumbnail from "./Thumbnail";

interface CollectionCardProps {
  name: string;
  isInCollection: boolean;
  collectionId: string;
  pinsCount: number;
  thumbnail?: string;
  productId: string;
  isAiGenerated?: boolean;
}

const CollectionCard = ({
  name,
  isInCollection,
  collectionId,
  pinsCount,
  thumbnail,
  productId,
  isAiGenerated,
}: CollectionCardProps) => {
  const queryClient = useQueryClient();
  const userId = useAppSelector(userSelector).id;
  const { sendRequest, loading } = useRequest();
  const hasThumbnail =
    thumbnail !== null && thumbnail !== undefined && thumbnail !== "";

  const handleAddToCollection = async () => {
    try {
      queryClient.setQueryData(
        ["collections", { userId, productId }],
        (oldData: Collection[]) => {
          if (oldData) {
            return oldData.map((collection) => {
              if (collection.id === collectionId) {
                return {
                  ...collection,
                  totalProducts: isInCollection
                    ? collection.totalProducts - 1
                    : collection.totalProducts + 1,
                  isProductInCollection: !isInCollection,
                };
              }
              return collection;
            });
          }
          return oldData;
        }
      );
      await sendRequest({
        method: isInCollection ? "DELETE" : "POST",
        url: isInCollection
          ? `Collection/DeleteProductFromCollection/${productId}/${collectionId}`
          : `Collection/addProductToCollection/${collectionId}`,
        data: [productId],
      });
      queryClient.invalidateQueries({
        queryKey: ["collections"],
      });
      queryClient.invalidateQueries({
        queryKey: ["collection", collectionId],
      });
    } catch (error) {
      queryClient.invalidateQueries({
        queryKey: [
          "collections",
          {
            userId,
            productId,
          },
        ],
      });
      toast.error("Something went wrong. Please try again.");
    }
  };

  return (
    <div className="ps-[15px] md:ps-[32px] pe-[19px] md:pe-[56px] pt-[21px] md:pt-[30px] pb-[22px] md:pb-[35px] rounded-[6px] md:rounded-[12px] bg-[#F1F1F1] dark:bg-[#2A2A2A] flex items-center justify-between relative z-0 overflow-hidden shrink-0">
      {isInCollection && (
        <div className="size-full inset-0 absolute -z-10 opacity-60 bg-[#41E862]" />
      )}
      {hasThumbnail && (
        <>
          <Thumbnail
            src={thumbnail}
            className="size-full absolute inset-0 -z-20 object-cover"
            isAiGenerated={isAiGenerated}
          />
          <div className="size-full inset-0 absolute -z-10 bg-black/20" />
        </>
      )}
      <div className="space-y-[5px]">
        <h1
          className={cn(
            "text-lg md:text-[30px] leading-[27px] md:leading-[45px]",
            hasThumbnail ? "text-white" : "text-black dark:text-white"
          )}
        >
          {name}
        </h1>
        <p
          className={cn(
            "text-xs md:text-lg font-medium leading-[18px] md:leading-[27px]",
            hasThumbnail ? "text-white" : "text-tertiary"
          )}
        >
          {pinsCount} pins
        </p>
      </div>
      <Button
        variant={hasThumbnail ? "transparent-inverted" : "transparent"}
        tiny
        icon={PlusIcon}
        iconClassName={cn(
          "size-[18px] md:size-[26px] transition-transform duration-200",
          {
            "rotate-45": isInCollection,
          }
        )}
        disabled={loading}
        onClick={() => handleAddToCollection()}
      />
    </div>
  );
};

export default CollectionCard;
