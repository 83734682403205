import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useRequest } from "@/hooks/useRequest";
import { Collection } from "@/shared/types/collection";
import { useAppSelector } from "@/redux/hooks";
import { userSelector } from "@/redux/App";
import Button from "@/components/Button";
import Modal from "@/components/Modal";
import NewCollectionModal from "@/pages/CreatorDashboard/Collections/components/NewCollectionModal";
import EmptyState from "@/pages/CreatorDashboard/Collections/components/EmptyState";
import toast from "react-hot-toast";
import CircleLoader from "@/components/Loaders/CircleLoader";
import CollectionCard from "./CollectionCard";

interface AddToCollectionProps {
  open: boolean;
  onClose: () => void;
  productId: string;
}

const AddToCollection = ({
  open,
  onClose,
  productId,
}: AddToCollectionProps) => {
  const { sendRequest } = useRequest<{
    data: {
      $values: Collection[];
    };
  }>();
  const userId = useAppSelector(userSelector).id;
  const [newCollectionModalOpen, setNewCollectionModalOpen] = useState(false);

  const {
    data: collections,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [
      "collections",
      {
        userId,
        productId,
      },
    ],
    queryFn: async () => {
      try {
        const res = await sendRequest({
          method: "GET",
          url: `Collection/getAllCollections?productCount=3&productId=${productId}`,
        });
        const data = res.data.data.$values;
        return data;
      } catch (error) {
        toast.error(
          "Your collections could not be retrieved. Please try to refresh the page."
        );
        console.error(error);
      }
    },
    enabled: open,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 60000,
    gcTime: 60000,
  });

  return (
    <>
      <NewCollectionModal
        open={newCollectionModalOpen}
        onClose={() => setNewCollectionModalOpen(false)}
      />
      <Modal
        open={open}
        onClose={onClose}
        className="!p-0 max-w-[941px] dark:bg-[#1A1919]"
      >
        <div className="px-[20px] md:px-[40px] py-[30px] md:py-[40px] h-[352px] md:h-[537px] overflow-auto custom-scrollbar">
          <h2 className="text-lg md:text-[24px] md:font-bold mb-[25px]">
            Add to collection
          </h2>
          <div className="flex flex-col gap-[10px] md:gap-[25px] h-full">
            {isLoading && (
              <CircleLoader className="size-[32px] md:size-[52px] m-auto" />
            )}
            {(isError || collections?.length === 0) && (
              <EmptyState className="!m-auto sm:!m-auto" />
            )}
            {collections?.map((collection) => (
              <CollectionCard
                key={collection.id}
                productId={productId}
                name={collection.name}
                isInCollection={collection?.isProductInCollection ?? false}
                collectionId={collection.id}
                pinsCount={collection?.totalProducts ?? 0}
                thumbnail={
                  (collection?.thumbnails?.$values[0]?.fileUrl ||
                    collection?.thumbnails?.$values[0]?.thumbnail) ??
                  ""
                }
                isAiGenerated={
                  collection?.thumbnails?.$values[0]?.isAiGenerated
                }
              />
            ))}
          </div>
        </div>

        <div className="px-[20px] md:px-[40px] flex max-md:flex-col items-center justify-end gap-[15px] md:gap-[40px] dark:bg-[#232323] shadow-default dark:shadow-default-dark shadow-[#A9A9A9]/20 dark:shadow-[#000000]/25 pt-[27px] md:pt-[25px] pb-[36px] md:pb-[30px]">
          <Button
            variant="link-unstyled"
            className="max-md:order-2"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="blue"
            className="max-md:w-full !px-[20px]"
            onClick={() => setNewCollectionModalOpen(true)}
          >
            Create new collection
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default AddToCollection;
