import { cn } from "@/utils/utils";
import NoCollectionLight from "@/assets/icons/empty_states/no_collection_light.svg?react";
import NoCollectionDark from "@/assets/icons/empty_states/no_collection_dark.svg?react";
import Button from "@/components/Button";

interface EmptyStateProps {
  onClick?: () => void;
  className?: string;
}

const EmptyState = ({ onClick, className }: EmptyStateProps) => {
  return (
    <div
      className={cn(
        "flex flex-col items-center !mt-[60px] sm:!mt-[170px]",
        className
      )}
    >
      <div className="mb-[14px] sm:mb-[20px]">
        <NoCollectionLight className="dark:hidden max-sm:w-[101px] max-sm:h-[97px]" />
        <NoCollectionDark className="hidden dark:block max-sm:w-[101px] max-sm:h-[97px]" />
      </div>
      <h1 className="font-medium text-lg sm:text-[24px] mb-[8px] sm:mb-[6px]">
        No collections found
      </h1>
      <p className="text-tertiary font-medium max-sm:text-xs mb-[20px] sm:mb-[28px] max-w-[377px] text-center">
        Create collections and start sharing and organizing your content.
      </p>
      {onClick && <Button onClick={onClick}>Create collection</Button>}
    </div>
  );
};

export default EmptyState;
