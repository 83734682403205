import { Collection } from "@/shared/types/collection";
import { Product } from "@/shared/types/product";
import { sendRequest } from "@/utils/sendRequest";

export const collectionQuery = (id) => ({
  queryKey: ["collection", id],
  queryFn: async ({
    pageParam,
  }): Promise<{
    data: {
      currentPage: number;
      nextPage: number;
      collection: Collection;
      products: {
        $values: Product[];
      };
    };
  }> => {
    const response = await sendRequest({
      method: "GET",
      url: `Collection/getCollectionById/${id}?page=${
        pageParam ?? 1
      }&pageSize=18`,
    });
    return await response.data;
  },
  refetchOnMount: true,
  refetchOnReconnect: false,
  staleTime: 1000 * 60 * 5,
  gcTime: 1000 * 60 * 60 * 24,
  initialPageParam: 1,
  getNextPageParam: (lastPage) => lastPage.data.nextPage,
});

export const collectionLoader =
  (queryClient) =>
  ({ params }) => {
    const query = collectionQuery(params.collectionId);
    return queryClient.fetchInfiniteQuery(query);
  };
