import { useNavigate } from "react-router";
import { useAppSelector } from "@/redux/hooks";
import { logout, logoutPopupSelector, showLogoutPopup } from "@/redux/App";
import { resetCart } from "@/redux/Cart";
import { useDispatch } from "react-redux";
import Modal from "@/components/Modal";
import IconWShadow from "@/components/IconWShadow";
import Button from "@/components/Button";

import LogoutIcon from "@/assets/icons/logout.svg?react";
import { GoArrowLeft } from "react-icons/go";

const LogoutModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logoutModal = useAppSelector(logoutPopupSelector) ?? false;

  const handleClose = () => {
    dispatch(showLogoutPopup(false));
  };

  const handleSignOut = () => {
    dispatch(logout());
    dispatch(resetCart());
    handleClose();
    navigate("/");
  };

  return (
    <Modal
      open={logoutModal}
      onClose={() => dispatch(showLogoutPopup(false))}
      className="max-md:py-10 !max-w-[320px] md:!max-w-[462px] h-full max-h-[416px] md:max-h-[384px] !py-4 flex items-center justify-center"
    >
      <div className="space-y-9">
        <div className="text-center">
          <IconWShadow className="mb-5 text-white">
            <LogoutIcon width={24} />
          </IconWShadow>
          <h4 className="font-semibold mb-3">Log out</h4>
          <p className="text-tertiary text-xs md:text-sm">
            Are you sure you want to log out of your account?
          </p>
        </div>
        <div className="flex max-md:flex-col items-center justify-center gap-6">
          <Button
            type="button"
            variant="link-unstyled"
            icon={GoArrowLeft}
            onClick={() => handleClose()}
            className="!gap-2 max-md:order-2"
            iconClassName="size-[20px] max-md:hidden max-md:w-full"
          >
            Back
          </Button>
          <Button
            onClick={() => handleSignOut()}
            className="w-full md:w-[173px]"
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default LogoutModal;
