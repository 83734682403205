import { Outlet } from "react-router";
import TabButton from "./components/TabButton";

const PerformanceLayout = () => {
  return (
    <>
      <nav className="border-b border-[#E4E4E4]/60 dark:border-[#5C5C5C]/60 mb-6 px-2 overflow-x-auto whitespace-nowrap scrollbar-hide md:mt-[61px]">
        <ul className="flex items-center gap-5">
          <TabButton href="/creator-dashboard/performance">Overview</TabButton>
          <TabButton href="/creator-dashboard/performance/audience">
            Audience
          </TabButton>
          <TabButton href="/creator-dashboard/performance/sales">
            Sales
          </TabButton>
          <TabButton href="/creator-dashboard/performance/content">
            Content
          </TabButton>
          <TabButton href="/creator-dashboard/performance/insights">
            Insights
          </TabButton>
        </ul>
      </nav>
      <div className="container mx-0 px-0">
        <Outlet />
      </div>
    </>
  );
};

export default PerformanceLayout;
