import { User } from "@/shared/types/user";
import { sendRequest } from "@/utils/sendRequest";
import { store } from "@/redux/store";

export const userQuery = () => ({
  queryKey: [
    "dashboard",
    {
      userId: store.getState().appSlice.user.id,
    },
  ],
  queryFn: async () => {
    const response = await sendRequest<{
      data: User;
    }>({
      method: "GET",
      url: "Users",
    });
    return response.data.data;
  },
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  staleTime: 1000 * 60 * 60 * 24,
  gcTime: 1000 * 60 * 60 * 24,
});

export const userLoader = (queryClient) => () => {
  const query = userQuery();
  return queryClient.fetchQuery(query);
};
