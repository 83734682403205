import axios from "axios";
import { Cookies } from "react-cookie";
import { updateToken } from "@/redux/App";
import { store } from "@/redux/store";
import { parseISO } from "date-fns";

const cookies = new Cookies();

let isRefreshing = false;
let failedQueue: {
  resolve: (value?: string | PromiseLike<string>) => void;
  reject: (reason?: any) => void;
}[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token ?? "");
    }
  });
  failedQueue = [];
};

export const refreshToken = async (token, refreshToken) => {
  if (isRefreshing) {
    return new Promise((resolve, reject) => {
      failedQueue.push({ resolve, reject });
    });
  }

  isRefreshing = true;

  try {
    const response = await axios({
      method: "POST",
      url: `${
        (import.meta.env.VITE_BACKEND_URL ?? "http://localhost:3000") +
        import.meta.env.VITE_API_POSTFIX
      }Accounts/RefreshToken`,
      data: {
        token,
        refreshToken,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const newToken = response.data.token;
    if (newToken === "Token already used" || newToken === "Token not expired") {
      isRefreshing = false;
      processQueue(null, token);
      return token;
    }

    const newRefreshToken = response.data.refreshToken;

    // Get cookies expiry dates
    const expiryDate = cookies.get("mo7tawa-token-expires-at")
      ? parseISO(cookies.get("mo7tawa-token-expires-at"))
      : undefined;

    cookies.set("mo7tawa-token", newToken, { path: "/", expires: expiryDate });
    cookies.set("mo7tawa-refresh-token", newRefreshToken, {
      path: "/",
      expires: expiryDate,
    });
    store.dispatch(
      updateToken({
        token: newToken,
        refreshToken: newRefreshToken,
      })
    );

    isRefreshing = false;
    processQueue(null, newToken);
    return newToken;
  } catch (error) {
    isRefreshing = false;
    processQueue(error, null);
    cookies.remove("mo7tawa-token");
    cookies.remove("mo7tawa-token-expires-at");
    cookies.remove("mo7tawa-refresh-token");
    window.location.replace("/sign-in");
    throw error;
  }
};
