import { Currency } from "./currencies";

export type User = {
  email?: string | null;
  firstName: string | null;
  lastName: string | null;
  userName: string | null;
  about: string | null;
  instagram: string | null;
  tiktok: string | null;
  discord: string | null;
  portfolioLink: string | null;
  profilePicture: string | null;
  userId?: string;
  coverPhoto?: string | null;
};

export type UserInfo = {
  id?: string;
  kycStatus?: number | null;
  email?: string | null;
  fullName?: string | null;
  firstName?: string | null;
  bankSetup?: boolean;
  categorySetup?: boolean;
  profilePicture?: string | null;
  roles?: UserRoles[];
  companyId?: string | null;
  companySetup?: boolean;
  isAdmin?: boolean;
  userName?: string | null;
  coverPhoto?: string | null;
  categoryIds?: {
    $values: string[];
  };
  preferredCurrency?: Currency;
  role?: {
    $values: UserRoles[];
  };
};

export enum KYCStatus {
  "Pending" = 1,
  "Processing" = 2,
  "Reviewing" = 3,
  "Verified" = 4,
  "Rejected" = 5,
  "Timeout" = 6,
  "Cancelled" = 7,
}

export type UserRoles =
  | "AppUser"
  | "Creator"
  | "CompanyAdmin"
  | "CompanyMember";
