import { useState } from "react";
import { useRequest } from "@/hooks/useRequest";
import { useQueryClient } from "@tanstack/react-query";
import { cn } from "@/utils/utils";
import { useAppSelector } from "@/redux/hooks";
import { isLoggedInSelector } from "@/redux/App";
import { Product } from "@/shared/types/product";
import toast from "react-hot-toast";
import Button from "@/components/Button";
import AddToCollection from "@/features/Collection/components/AddToCollection";

import PlusIcon from "@/assets/icons/plus.svg?react";

interface CollectionButtonProps {
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconClassName?: string;
  className?: string;
  muted?: boolean;
  productId?: string;
  collectionId?: string;
}

const CollectionButton = ({
  icon,
  iconClassName,
  className,
  muted,
  productId,
  collectionId,
}: CollectionButtonProps) => {
  const { sendRequest } = useRequest();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const isLoggedIn = useAppSelector(isLoggedInSelector);

  const handleRemoveFromCollection = async () => {
    if (!collectionId) return;
    try {
      queryClient.setQueriesData(
        {
          queryKey: ["collection", collectionId],
        },
        (oldData: {
          pageParams: number[];
          pages: {
            data: {
              products: {
                $values: Product[];
              };
            };
          }[];
        }) => {
          if (!oldData) return oldData;

          const newPages = oldData.pages.map((page) => ({
            ...page,
            data: {
              ...page?.data,
              products: {
                $values: page?.data?.products?.$values?.filter(
                  (prod) => prod.productId !== productId
                ),
              },
            },
          }));

          return {
            ...oldData,
            pages: newPages,
          };
        }
      );
      await sendRequest({
        method: "DELETE",
        url: `Collection/DeleteProductFromCollection/${productId}/${collectionId}`,
        data: [productId],
      });
      await queryClient.invalidateQueries({
        queryKey: ["collections"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["approvedContent"],
      });
    } catch (error) {
      toast.error("The content could not be removed from the collection.");
      queryClient.invalidateQueries({
        queryKey: ["collection", collectionId],
      });
    }
  };

  return (
    <>
      <AddToCollection
        open={open}
        onClose={() => setOpen(false)}
        productId={productId ?? ""}
      />
      <Button
        href={!isLoggedIn ? `/sign-in?target=/product/${productId}` : undefined}
        tiny={muted}
        variant={muted ? "default-dimmed" : "link-unstyled"}
        className={cn(
          "relative text-white hover:text-[#232323]",
          collectionId ? "text-accent-blue dark:text-accent-main" : "",
          className
        )}
        aria-label={
          collectionId ? "Remove from Collection" : "Add to Collection"
        }
        icon={icon ? icon : PlusIcon}
        iconClassName={cn("size-[16px]", iconClassName)}
        onClick={() =>
          collectionId ? handleRemoveFromCollection() : setOpen(true)
        }
      />
    </>
  );
};

export default CollectionButton;
