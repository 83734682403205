import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { blobToFile, fileToBase64 } from "@/utils/helpers";
import { generatePfpFileName } from "./utils/helpers";
import { cn } from "@/utils/utils";
import getCroppedImg from "./utils/cropImage";
import toast from "react-hot-toast";
import Modal from "@/components/Modal";
import Cropper from "react-easy-crop";
import Slider from "@/components/Slider";

interface CroppableUploaderProps {
  children: (props: any) => React.ReactNode;
  onChange: (image: File) => void;
  className?: string;
  sizeInMb?: number;
  rounded?: boolean;
  maxWidth?: number;
  aspect?: number;
  modalClassName?: string;
}

const CroppableUploader = ({
  onChange,
  children,
  className,
  sizeInMb = 4,
  rounded = true,
  maxWidth = 320,
  aspect = 1,
  modalClassName,
}: CroppableUploaderProps) => {
  const [image, setImage] = useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [editor, showEditor] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onUpload = useCallback(async (acceptedFiles) => {
    // Reset state
    setCrop({ x: 0, y: 0 });
    setZoom(1);

    fileToBase64(acceptedFiles?.[0])
      .then((res: string) => {
        setImage(res);
        showEditor(true);
      })
      .catch((error) => console.error(error));
    showEditor(true);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: onUpload,
    onDropRejected(fileRejections, event) {
      toast.error(
        `Only JPEG/PNG/GIF files that are ${sizeInMb}MB or under are accepted.`
      );
    },
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/gif": [],
    },
    maxSize: sizeInMb * 1024 * 1024,
    maxFiles: 1,
  });

  const handleCancel = () => {
    showEditor(false);
    setImage(null);
  };

  const handleSave = async () => {
    try {
      const croppedImage: any = await getCroppedImg(
        image,
        croppedAreaPixels,
        0,
        maxWidth
      );
      setImage(croppedImage);
      showEditor(false);

      const response = await fetch(croppedImage);
      const blob = await response.blob();

      const file = blobToFile(blob, generatePfpFileName());
      onChange(file);
    } catch (error) {
      console.error(`Failed to save picture. Error: ${error}`);
    }
  };

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  return (
    <>
      <Modal
        open={editor}
        onClose={() => handleCancel()}
        className={cn(
          "!max-w-[558px] !h-[551px] !p-0 overflow-clip",
          modalClassName
        )}
        closeIconClassName="hidden"
      >
        <div className="absolute top-0 left-0 right-0 bottom-[110px] flex items-center justify-center">
          {image && (
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              cropShape={rounded ? "round" : "rect"}
              showGrid={true}
              onCropChange={onCropChange}
              onCropComplete={onCropComplete}
              zoomWithScroll={true}
              onZoomChange={setZoom}
            />
          )}
        </div>
        <div className="absolute bottom-[70px] start-1/2 -translate-x-1/2">
          <Slider
            onChange={(val) => setZoom(val / 100)}
            min={100}
            max={1000}
            defaultValue={zoom}
          />
        </div>
        <div className="absolute bottom-5 end-8 flex items-center gap-[46px]">
          <button
            className="font-medium hover:underline"
            onClick={() => handleCancel()}
          >
            Cancel
          </button>
          <button
            className="font-medium hover:underline"
            onClick={() => handleSave()}
          >
            Save
          </button>
        </div>
      </Modal>
      <div className={cn("w-auto", className)} {...getRootProps()}>
        <input {...getInputProps()} />
        {children(image)}
      </div>
    </>
  );
};

export default CroppableUploader;
