import { cn } from "@/utils/utils";
import { motion } from "framer-motion";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { isSidebarExpandedSelector, toggleSidebar } from "@/redux/App";
import Button from "@/components/Button";
import Divider from "@/components/Divider";
import Menu from "./Menu";
import Avatar from "./Avatar";

import ArrowIcon from "@/assets/icons/chevrons/chevron.svg?react";

interface SidebarProps {
  username?: string | null;
  name?: string | null;
  pfp?: string | null;
}

const Sidebar = ({ username, name, pfp }: SidebarProps) => {
  const dispatch = useAppDispatch();
  const isExpanded = useAppSelector(isSidebarExpandedSelector);

  const handleExpand = () => {
    dispatch(toggleSidebar());
  };

  return (
    <motion.section
      className={cn(
        "bg-tertiary-50 dark:bg-[#232323] h-screen sticky top-0 shrink-0 flex flex-col items-stretch",
        isExpanded ? "p-[24px]" : "py-[24px] px-[11.5px]"
      )}
      animate={{ width: isExpanded ? 259 : 72 }}
    >
      <Button
        variant="light"
        icon={ArrowIcon}
        tiny
        className={cn(
          "size-[28px] bg-white border-[1px] border-tertiary-50 dark:border-[#2D2D2D] absolute top-[34px] -end-[14px]"
        )}
        iconClassName={cn(
          "size-[10px] text-tertiary",
          isExpanded ? "rotate-90" : "-rotate-90"
        )}
        onClick={() => handleExpand()}
        aria-label="Expand Sidebar"
      />
      <div
        className={cn(
          "flex items-center gap-[12px]",
          !isExpanded && "justify-center"
        )}
      >
        <Avatar expanded={isExpanded} src={pfp} />
        {isExpanded && (
          <div className="flex flex-col gap-[4px] truncate">
            {username && (
              <span className="uppercase font-medium text-[10px] leading-[12px] text-tertiary truncate">
                @{username}
              </span>
            )}
            <p className="font-medium text-sm leading-[20px] truncate">
              {name}
            </p>
          </div>
        )}
      </div>
      <Divider gradient className="mt-[15px] mb-[24px] opacity-30" />
      <Menu expanded={isExpanded} />
    </motion.section>
  );
};

export default Sidebar;
