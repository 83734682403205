import { useState } from "react";
import { useRequest } from "@/hooks/useRequest";
import { useAppSelector } from "@/redux/hooks";
import { isLoggedInSelector } from "@/redux/App";
import { AnimatePresence, motion, useAnimate } from "framer-motion";
import { cn } from "@/utils/utils";
import { useQueryClient } from "@tanstack/react-query";
import Button, { ButtonVariants } from "@/components/Button";
import HeartOutline from "@/assets/icons/hearts/heart_outline.svg?react";
import HeartFilled from "@/assets/icons/hearts/heart_filled.svg?react";
import { Product } from "@/shared/types/product";
import { useLocation } from "react-router";

interface FavButtonProps {
  className?: string;
  productId?: string;
  isFavorite?: boolean;
  muted?: boolean;
  iconClassName?: string;
  variant?: ButtonVariants;
  onClick?: () => void;
}

const FavButton = ({
  className,
  productId,
  isFavorite,
  muted,
  iconClassName,
  variant = "default-dimmed",
  onClick,
}: FavButtonProps) => {
  const { pathname } = useLocation();
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const { sendRequest } = useRequest();
  const [isLiked, setIsLiked] = useState(isFavorite);
  const [scope, animate] = useAnimate();
  const queryClient = useQueryClient();

  const handleFavoriteClick = async () => {
    if (!isLoggedIn) return;
    if (onClick) onClick();
    const animateFav = async () => {
      await animate(
        scope.current,
        { scale: 0.5 },
        {
          duration: 0.5,
        }
      );
      await animate(
        scope.current,
        { scale: 1 },
        {
          duration: 0.5,
        }
      );
    };
    setIsLiked((prev) => {
      const newState = !prev;
      if (newState) animateFav();
      return newState;
    });
    // Call Favorite API
    if (productId) {
      try {
        await queryClient.setQueryData(
          ["product", productId],
          (oldData: Product) => {
            if (oldData) {
              return {
                ...oldData,
                isFavorite: !oldData.isFavorite,
              };
            }
            return oldData;
          }
        );
        queryClient.setQueriesData(
          {
            queryKey: ["favorites"],
          },
          (oldData: {
            pageParams: number[];
            pages: {
              data: {
                favorites: {
                  $values: Product[];
                };
              };
            }[];
          }) => {
            if (!oldData) return oldData;

            const newPages = oldData.pages.map((page) => ({
              ...page,
              data: {
                ...page?.data,
                favorites: {
                  $values: page?.data?.favorites?.$values?.filter(
                    (prod) => prod.productId !== productId
                  ),
                },
              },
            }));

            return {
              ...oldData,
              pages: newPages,
            };
          }
        );
        await sendRequest({
          method: isLiked ? "DELETE" : "POST",
          url: `ProductFavorite/${productId}`,
        });
        if (pathname !== "/creator-dashboard/favorites") {
          queryClient.invalidateQueries({
            queryKey: ["favorites"],
          });
        }
        queryClient.setQueryData(
          ["product", productId],
          (oldData: { product: Product; isFavorite: boolean }) => {
            return {
              ...oldData,
              isFavorite: !isFavorite,
            };
          }
        );
      } catch (error) {
        console.error(error);
        queryClient.invalidateQueries({
          queryKey: ["favorites"],
        });
        queryClient.invalidateQueries({
          queryKey: ["product", productId],
        });
      }
    }
  };

  return (
    <Button
      tiny={muted}
      variant={muted ? "default-dimmed" : "link-unstyled"}
      className={cn(
        "relative hover:!text-error-default hover:dark:!text-error-dark-default text-white !transition-all duration-200",
        className
      )}
      href={
        isLoggedIn
          ? undefined
          : `/sign-in${productId ? `?target=/product/${productId}` : ""}`
      }
      aria-label="Add to Favorites"
      onClick={() => handleFavoriteClick()}
      iconClassName="size-[16px]"
    >
      <div ref={scope}>
        <HeartOutline
          className={cn(
            "size-[20px]",
            {
              "text-error-default dark:text-error-dark-default": isLiked,
            },
            iconClassName
          )}
        />
      </div>

      <AnimatePresence>
        {isLiked && (
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            transition={{ duration: 0.7, type: "spring" }}
            className="absolute inset-0 flex items-center justify-center"
          >
            <HeartFilled
              className={cn(
                "fill-error-default dark:fill-error-dark-default size-[20px]",
                iconClassName
              )}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Button>
  );
};

export default FavButton;
