import { Helmet } from "react-helmet-async";
import Button from "@/components/Button";
import Header from "@/components/Header";

const NotFoundPage = () => {
  return (
    <>
      <Helmet>
        <title>Page Not Found - {import.meta.env.VITE_NAME}</title>
      </Helmet>
      <Header logoOnly searchBar={false} userPfPOnly />
      <main className="container">
        <div className="text-center mb-16">
          <img
            src="/images/not_found/404_light.png"
            alt="Page not found"
            className="dark:hidden mx-auto w-[720px]"
          />
          <img
            src="/images/not_found/404_dark.png"
            alt="Page not found"
            className="hidden dark:block mx-auto w-[720px]"
          />
          <h1 className="font-bold -mt-10">Whoops, this page is gone!</h1>
          <p className="mt-6 text-tertiary-main max-w-sm mx-auto">
            Beautiful, free images gifted by the world’s most generous community
            of photographers.
          </p>
          <Button href="/" className="!px-16 mt-4 max-w-64 mx-auto">
            Back To Home
          </Button>
        </div>
      </main>
    </>
  );
};

export default NotFoundPage;
