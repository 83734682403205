// Credit: https://codesandbox.io/s/react-easy-crop-demo-with-cropped-output-q8q1mnr01w?file=/src/cropImage.js:0-2615

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.src = url;
  });

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export default async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  maxWidth = 320,
  flip = { horizontal: false, vertical: false }
) {
  const image: any = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  const croppedCanvas = document.createElement("canvas");

  const croppedCtx = croppedCanvas.getContext("2d");

  if (!croppedCtx) {
    return null;
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width;
  croppedCanvas.height = pixelCrop.height;

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // Calculate the scale factor
  const scaleFactor = maxWidth / croppedCanvas.width;
  const outputWidth = maxWidth;
  const outputHeight = croppedCanvas.height * scaleFactor;

  // Create a new canvas for the resized image
  const resizedCanvas = document.createElement("canvas");
  resizedCanvas.width = outputWidth;
  resizedCanvas.height = outputHeight;
  const resizedCtx = resizedCanvas.getContext("2d");

  if (!resizedCtx) {
    return null;
  }

  // Draw the scaled image onto the resized canvas
  resizedCtx.drawImage(
    croppedCanvas,
    0,
    0,
    croppedCanvas.width,
    croppedCanvas.height,
    0,
    0,
    outputWidth,
    outputHeight
  );

  // File type
  let mimeType = "image/jpeg";
  try {
    mimeType = imageSrc.match(/^data:(.*);base64,/)[1];
  } catch (error) {
    console.warn(
      "Unable to determine the MIME type. Using default: image/jpeg"
    );
  }

  // As Base64 string
  // return croppedCanvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    resizedCanvas.toBlob((file: any) => {
      resolve(URL.createObjectURL(file));
    }, mimeType);
  });
}
