import { cn } from "@/utils/utils";
import DefaultAvatar from "./DefaultAvatar";
import { Link } from "react-router";

interface AvatarPillWPopoverProps {
  id?: string | null;
  className?: string;
  buttonClassName?: string;
  name?: string | null;
  src?: string | null;
  isVisible?: boolean;
}

const AvatarPillWPopover = ({
  id,
  className = "",
  name,
  src,
  isVisible = true,
}: AvatarPillWPopoverProps) => {
  return (
    <Link
      to={`/user/${id}`}
      aria-label={`View profile of ${name}`}
      className={cn(
        "flex items-center gap-1 sm:gap-2 relative hover:no-underline text-sm truncate unstyled-link",
        className
      )}
    >
      <DefaultAvatar
        src={src}
        className="size-[18px] sm:size-[22px] border-none"
        isVisible={isVisible}
      />
      <p className="text-white font-medium truncate text-xs sm:text-sm">
        {name || "Anonymous"}
      </p>
    </Link>
  );
};

export default AvatarPillWPopover;
