import { cn } from "@/utils/utils";

type IconAccentColors = "purple" | "blue" | "red";

interface IconWShadowProps {
  children: React.ReactNode;
  color?: IconAccentColors;
  className?: string;
}

const IconWShadow = ({
  children,
  color = "purple",
  className = "",
}: IconWShadowProps) => {
  const colorClassName: Record<IconAccentColors, string> = {
    purple:
      "border-accent-purple dark:border-accent-main shadow-[#8A69CC_0px_4px_28px_5px] dark:shadow-[#c3f500_0px_4px_24px_0px]",
    blue: "border-accent-blue dark:border-accent-main shadow-[#418FE8_0px_4px_28px_5px] dark:shadow-[#c3f500_0px_4px_24px_0px]",
    red: "border-error-default shadow-[0px_4px_18px_10px_rgba(245,_44,_31,_0.15)]",
  };

  return (
    <div
      className={cn(
        "size-[60px] rounded-full bg-black border-[6px] flex items-center justify-center mx-auto relative shrink-0",
        className,
        colorClassName[color]
      )}
    >
      {children}
    </div>
  );
};

export default IconWShadow;
