import { cn } from "@/utils/utils";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useRequest } from "@/hooks/useRequest";
import { Product, ProductStatus } from "@/shared/types/product";
import Modal from "@/components/Modal";
import IconWShadow from "@/components/IconWShadow";
import Button from "@/components/Button";

import TrashIcon from "@/assets/icons/trash.svg?react";

interface DeleteContentProps {
  productId?: string;
  onDelete?: () => void;
  muted?: boolean;
  className?: string;
  productStatus?: ProductStatus;
}

const DeleteContent = ({
  productId,
  onDelete,
  muted,
  className,
  productStatus,
}: DeleteContentProps) => {
  const queryClient = useQueryClient();
  const { sendRequest, loading } = useRequest();
  const [modal, showModal] = useState(false);

  const handleModalClose = () => {
    showModal(false);
  };

  const handleDeleteContent = async () => {
    if (onDelete) {
      onDelete();
    }

    queryClient.setQueriesData(
      {
        queryKey: ["products"],
      },
      (oldData: {
        pageParams: number[];
        pages: {
          data: {
            liveProducts: number;
            reviewedProducts: number;
            rejectedProducts: number;
            products: {
              $values: Product[];
            };
          };
        }[];
      }) => {
        if (!oldData) return oldData;

        const newPages = oldData.pages.map((page) => ({
          ...page,
          data: {
            ...page?.data,
            liveProducts:
              productStatus === ProductStatus.Approved
                ? page.data.liveProducts - 1
                : page.data.liveProducts,
            reviewedProducts:
              productStatus === ProductStatus.PendingApproval
                ? page.data.reviewedProducts - 1
                : page.data.reviewedProducts,
            products: {
              $values: page?.data?.products?.$values?.filter(
                (prod) => prod.productId !== productId
              ),
            },
          },
        }));

        return {
          ...oldData,
          pages: newPages,
        };
      }
    );

    try {
      await sendRequest({
        method: "DELETE",
        url: `Products/${productId}`,
      });

      handleModalClose();
    } catch (error) {
      queryClient.invalidateQueries({
        queryKey: ["products"],
      });
    }
  };

  return (
    <>
      <Modal
        open={modal}
        onClose={handleModalClose}
        className="w-[20rem] h-[24.563rem] sm:w-[28.875rem] sm:h-[24rem] flex flex-col items-center sm:p-0 !pt-[72px]"
      >
        <IconWShadow className="mb-5" color="red">
          <TrashIcon className="text-white size-[1.5rem]" />
        </IconWShadow>
        <div className="text-center flex flex-col gap-2 sm:gap-3 items-center max-sm:w-full">
          <h4 className="font-semibold">Delete content</h4>
          <p className="text-xs sm:text-sm text-tertiary max-w-[70%]">
            Are you sure you want to delete this asset permanently?
          </p>
          <div className="flex max-sm:flex-col items-center mt-7 max-sm:w-full">
            <Button
              className="max-sm:w-full sm:!ps-2 sm:pe-5 max-sm:order-2 after:!hidden hover:!text-black dark:hover:!text-white"
              onClick={handleModalClose}
              variant="transparent"
            >
              Back
            </Button>
            <Button
              className="max-sm:!w-full sm:w-[10.813rem] h-[3.25rem]"
              onClick={handleDeleteContent}
              disabled={loading}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
      <Button
        icon={TrashIcon}
        iconClassName={cn("size-[16px]")}
        variant="link-unstyled"
        onClick={() => showModal(true)}
        className={cn(
          "hover:text-error-default hover:dark:text-error-dark-default text-white",
          className
        )}
      />
    </>
  );
};

export default DeleteContent;
