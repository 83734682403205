import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router";
import { isCreatorSelector, showLogoutPopup } from "@/redux/App";
import { useAppSelector } from "@/redux/hooks";
import { cn } from "@/utils/utils";
import Divider from "@/components/Divider";
import ThemeToggler from "./ThemeToggler";

import WandIcon from "@/assets/icons/creator_dashboard/wand.svg?react";
import CreatedIcon from "@/assets/icons/creator_dashboard/created.svg?react";
import HelpIcon from "@/assets/icons/creator_dashboard/help.svg?react";
import FavoritesIcon from "@/assets/icons/hearts/heart_outline.svg?react";
import CollectionIcon from "@/assets/icons/creator_dashboard/collection.svg?react";
import LogoutIcon from "@/assets/icons/creator_dashboard/logout.svg?react";
import HomeIcon from "@/assets/icons/creator_dashboard/home.svg?react";
import SettingsIcon from "@/assets/icons/creator_dashboard/cog.svg?react";

interface MenuProps {
  expanded: boolean;
}

const Menu = ({ expanded }: MenuProps) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isCreator = useAppSelector(isCreatorSelector);

  return (
    <nav className="flex flex-col justify-between flex-1 h-full overflow-y-auto overflow-x-hidden">
      <div
        className={cn(
          "flex flex-col",
          !expanded && "items-center justify-center"
        )}
      >
        <p
          className={cn(
            "font-medium text-[10px] leading-[12px] text-tertiary uppercase mb-[12px]",
            expanded && "px-[12px]"
          )}
        >
          Main
        </p>
        <ul className={cn("space-y-[8px]", expanded && "px-[12px]")}>
          <MenuLink icon={HomeIcon} href="/" expanded={expanded}>
            Homepage
          </MenuLink>
          <MenuLink
            icon={WandIcon}
            href="/creator-dashboard/purchases"
            active={pathname === "/creator-dashboard/purchases"}
            expanded={expanded}
          >
            Purchased
          </MenuLink>
          {isCreator && (
            <MenuLink
              icon={CreatedIcon}
              href="/creator-dashboard"
              active={pathname === "/creator-dashboard"}
              expanded={expanded}
            >
              Created
            </MenuLink>
          )}
          <MenuLink
            icon={CollectionIcon}
            href="/creator-dashboard/collections"
            active={pathname === "/creator-dashboard/collections"}
            expanded={expanded}
          >
            Collections
          </MenuLink>
          <MenuLink
            icon={FavoritesIcon}
            href="/creator-dashboard/favorites"
            active={pathname === "/creator-dashboard/favorites"}
            expanded={expanded}
          >
            Favorites
          </MenuLink>
        </ul>
        <Divider gradient className="my-[24px] opacity-30" />
        <p
          className={cn(
            "font-medium text-[10px] leading-[12px] text-tertiary uppercase mb-[8px]",
            expanded && "px-[12px]"
          )}
        >
          Settings
        </p>
        <ul className={cn("space-y-[8px]", expanded && "px-[12px]")}>
          <MenuLink
            icon={SettingsIcon}
            href="/creator-dashboard/settings"
            active={pathname.startsWith("/creator-dashboard/settings")}
            expanded={expanded}
          >
            Settings
          </MenuLink>
        </ul>
      </div>
      <ul
        className={cn(
          "flex flex-col gap-[8px] mt-auto",
          !expanded ? "items-center justify-center" : "px-[12px]"
        )}
      >
        <ThemeToggler expanded={expanded} />
        <MenuLink href="/faq" icon={HelpIcon} expanded={expanded}>
          Help
        </MenuLink>
        <MenuLink
          icon={LogoutIcon}
          expanded={expanded}
          className="text-error-default dark:text-error-dark-default"
          onClick={() => dispatch(showLogoutPopup(true))}
        >
          Logout
        </MenuLink>
      </ul>
    </nav>
  );
};

export const MenuLink = ({
  icon: TabIcon,
  href,
  active = false,
  children,
  className,
  expanded,
  onClick,
  withBorder,
}: {
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  href?: string;
  active?: boolean;
  children: React.ReactNode;
  className?: string;
  expanded?: boolean;
  onClick?: () => void;
  withBorder?: boolean;
}) => {
  const linkClass = cn(
    "text-sm text-tertiary unstyled-link flex items-center gap-[12px] transition-colors duration-200 hover:text-black dark:hover:text-white py-[10px]",
    active && "text-black dark:text-white font-medium",
    withBorder && active
      ? "border-b-[2px] border-black dark:border-white"
      : "border-transparent",
    className
  );
  return (
    <li>
      {!href && onClick ? (
        <button
          type="button"
          className={linkClass}
          onClick={onClick}
          aria-label={children as string}
        >
          {TabIcon && <TabIcon className="size-[20px] shrink-0" />}
          {expanded && children}
        </button>
      ) : (
        <Link to={href ?? "#"} className={linkClass} title={children as string}>
          {TabIcon && <TabIcon className="size-[20px] shrink-0" />}
          {expanded && children}
        </Link>
      )}
    </li>
  );
};

export default Menu;
