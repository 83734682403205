import { FunctionComponent, SVGProps } from "react";
import { KYCStatus } from "@/shared/types/user";
import { cn } from "@/utils/utils";
import { Link } from "react-router";

// KYC Icons
import PendingIcon from "@/assets/icons/kyc/pending.svg?react";
import ProcessingIcon from "@/assets/icons/kyc/reviewing.svg?react";
import ReviewingIcon from "@/assets/icons/kyc/reviewing.svg?react";
import ApprovedIcon from "@/assets/icons/kyc/verified.svg?react";
import RejectedIcon from "@/assets/icons/kyc/rejected.svg?react";
import TimeoutIcon from "@/assets/icons/kyc/timeout.svg?react";

interface KYCBadgeProps {
  kycStatus: KYCStatus;
}

const KYCBadge = ({ kycStatus }: KYCBadgeProps) => {
  const icons: {
    [key in KYCStatus]: FunctionComponent<SVGProps<SVGSVGElement>>;
  } = {
    [KYCStatus.Pending]: PendingIcon,
    [KYCStatus.Processing]: ProcessingIcon,
    [KYCStatus.Reviewing]: ReviewingIcon,
    [KYCStatus.Verified]: ApprovedIcon,
    [KYCStatus.Rejected]: RejectedIcon,
    [KYCStatus.Timeout]: TimeoutIcon,
    [KYCStatus.Cancelled]: RejectedIcon,
  };
  const IconComponent = icons[kycStatus] || null;
  const iconClassName = {
    [KYCStatus.Pending]: "text-[#808080] dark:text-[#D0D0D0]",
    [KYCStatus.Processing]: "text-[#418FE8] dark:text-[#C3F500]",
    [KYCStatus.Reviewing]: "text-[#418FE8] dark:text-[#C3F500]",
    [KYCStatus.Verified]: "text-[#2F9616] dark:text-[#64EA9C]",
    [KYCStatus.Rejected]: "text-[#F52C1F] dark:text-[#F96158]",
    [KYCStatus.Timeout]: "text-[#8A69CC] dark:text-[#B69BED]",
    [KYCStatus.Cancelled]: "text-[#F52C1F] dark:text-[#F96158]",
  }[kycStatus];

  if (!(kycStatus in KYCStatus)) {
    return <></>;
  }

  return (
    <Link
      to="/creator-dashboard/settings"
      className="unstyled-link rounded-full shadow-default dark:shadow-default-dark bg-white/70 dark:bg-tertiary-925/70 backdrop-blur-md absolute -bottom-[7px] left-1/2 -translate-x-1/2 py-[3px] px-[8px] flex items-center gap-[6px]"
    >
      <span className="text-xs font-medium">{KYCStatus[kycStatus]}</span>
      {IconComponent && <IconComponent className={cn("", iconClassName)} />}
    </Link>
  );
};

export default KYCBadge;
