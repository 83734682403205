import { useQuery } from "@tanstack/react-query";
import { useWindowSize } from "@uidotdev/usehooks";
import { Helmet } from "react-helmet-async";
import { Outlet, useLoaderData } from "react-router";
import { userLoader, userQuery } from "./api";
import { User } from "@/shared/types/user";
import Sidebar from "./components/Sidebar";
import MobileTop from "./components/MobileTop";

const CreatorDashboardLayout = () => {
  const { width: windowWidth } = useWindowSize();
  const initialData = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof userLoader>>
  >;

  const { data: user } = useQuery<User>({
    ...userQuery(),
    initialData: initialData,
  });

  return (
    <>
      <Helmet>
        <title>{import.meta.env.VITE_NAME}</title>
      </Helmet>

      <main className="flex max-md:flex-col gap-[25px] md:gap-[30px]">
        {(windowWidth ?? 0) >= 768 && (
          <Sidebar
            username={user.userName}
            name={`${user.firstName} ${user.lastName}`}
            pfp={user.profilePicture}
          />
        )}
        {(windowWidth ?? 0) < 768 && (
          <MobileTop
            userId={(user.userName || user.userId) ?? ""}
            username={user.userName}
            name={`${user.firstName} ${user.lastName}`}
            pfp={user.profilePicture}
          />
        )}
        <section className="pb-[30px] flex-1 min-w-[auto] md:pe-[30px] flex flex-col items-stretch">
          <Outlet context={{ user }} />
        </section>
      </main>
    </>
  );
};

export default CreatorDashboardLayout;
