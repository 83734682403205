import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { useRequest } from "@/hooks/useRequest";
import { useAppSelector } from "@/redux/hooks";
import { userSelector } from "@/redux/App";
import Button from "@/components/Button";
import Modal from "@/components/Modal";
import Input from "@/components/Input";
import toast from "react-hot-toast";
import Checkbox from "@/components/Form/Checkbox";
import ConfirmDelete from "./ConfirmDelete";

interface NewCollectionModalProps {
  open: boolean;
  onClose: () => void;
  edit?: boolean;
  initial?: {
    id: string;
    name: string;
    description: string;
    isPrivate: boolean;
  };
}

type FormFields = {
  collectionName: string;
  description?: string;
  isPrivate?: boolean;
};

const NewCollectionModal = ({
  open,
  onClose,
  edit,
  initial,
}: NewCollectionModalProps) => {
  const userId = useAppSelector(userSelector).id;
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const { sendRequest, loading } = useRequest();
  const queryClient = useQueryClient();
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
    control,
  } = useForm<FormFields>();

  useEffect(() => {
    reset({
      collectionName: initial?.name ?? "",
      description: initial?.description ?? "",
      isPrivate: initial?.isPrivate ?? false,
    });
  }, [initial, reset]);

  const handleCreate: SubmitHandler<FormFields> = async (data) => {
    try {
      const res = await sendRequest({
        method: edit ? "PUT" : "POST",
        url: edit
          ? `Collection/updateCollection/${initial?.id}`
          : "Collection/addCollection",
        data: {
          name: data.collectionName,
          description: data.description,
          isPrivate: data.isPrivate,
        },
      });
      const out = await res.data;
      toast.success(
        out?.message ?? `Collection ${edit ? "updated" : "added"} successfully`
      );
      if (edit) {
        queryClient.invalidateQueries({
          queryKey: ["collection", initial?.id],
        });
        queryClient.refetchQueries({
          queryKey: [
            "collections",
            {
              userId,
            },
          ],
        });
      } else {
        queryClient.refetchQueries({
          queryKey: [
            "collections",
            {
              userId,
            },
          ],
        });
      }
      reset();
      onClose();
    } catch (error) {
      toast.error(
        `Sorry, your collection could not be ${
          edit ? "updated" : "added"
        }. Please try again later.`
      );
      console.error(error);
    }
  };

  return (
    <>
      <ConfirmDelete
        open={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        id={initial?.id ?? ""}
      />
      <Modal
        open={open}
        onClose={() => onClose()}
        className="!max-w-[941px] !p-0 dark:bg-[#1A1919]"
      >
        <form onSubmit={handleSubmit(handleCreate)}>
          <div className="px-[20px] md:px-[40px] pt-[32px] md:pt-[40px]">
            <h1 className="font-medium md:font-bold text-lg md:text-[24px] mb-[20px] md:mb-[38px]">
              {edit ? "Edit Collection" : "Create Collection"}
            </h1>
            <Input
              label="Collection Name"
              placeholder="Collection Name"
              className="font-medium"
              containerClassName="mb-[24px]"
              maxLength={250}
              {...register("collectionName", {
                required: true,
                maxLength: 250,
              })}
              error={errors.collectionName}
            />
            <Input
              label="Description"
              placeholder="Description"
              containerClassName="mb-[12px] md:mb-[15px]"
              textarea
              optional
              maxLength={100}
              counter={watch("description")?.length || 0}
              error={errors.description}
              {...register("description", {
                maxLength: 100,
              })}
            />
            <Controller
              control={control}
              name="isPrivate"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  label="Make collection private"
                  onChange={onChange}
                  checked={value}
                  fieldClassName="ps-[8px] md:ps-[10px]"
                  labelClassName="text-sm md:text-base"
                />
              )}
            />
          </div>
          <div className="px-[20px] md:px-[40px] md:pt-[25px] pb-[30px] flex max-md:flex-col items-center justify-between gap-[18px] shadow-[#A9A9A9]/20 dark:shadow-[#000000]/25 md:shadow-default md:dark:shadow-default-dark md:mt-[134px] max-md:mt-[26px] md:dark:bg-[#232323]">
            {edit && (
              <Button
                variant="link-unstyled"
                className="text-error-default dark:text-error-dark-default max-md:order-2"
                onClick={() => setIsDeleteOpen(true)}
                disabled={loading}
                type="button"
              >
                Delete collection
              </Button>
            )}
            <div className="md:ms-auto max-md:w-full flex items-center gap-[40px]">
              <Button
                variant="link-unstyled"
                onClick={() => onClose()}
                type="button"
                className="max-md:hidden"
              >
                Cancel
              </Button>
              <Button
                variant="blue"
                className="!px-[29px] max-md:w-full"
                type="submit"
                loading={loading}
              >
                {edit ? "Save" : "Create"}
              </Button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default NewCollectionModal;
