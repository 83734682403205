import { Product, ProductSize } from "@/shared/types/product";
import { sendRequest } from "@/utils/sendRequest";

export const productQuery = (id) => ({
  queryKey: ["product", id],
  queryFn: async () => {
    const response = await sendRequest<{
      data: {
        product: Product;
        isFavorite: boolean;
        purchasedSizes: {
          $values: ProductSize[];
        };
      };
    }>({
      method: "GET",
      url: `Products/${id}`,
    });
    return response.data.data;
  },
  refetchOnMount: false,
  refetchOnReconnect: false,
  staleTime: 1000 * 60 * 5,
  gcTime: 1000 * 60 * 60 * 24,
});

export const productLoader =
  (queryClient) =>
  ({ params }) => {
    const query = productQuery(params.productId);
    return queryClient.fetchQuery(query);
  };
