import { themeSelector } from "@/redux/App";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { cn } from "@/utils/utils";
import { Switch } from "@headlessui/react";

import SunIcon from "@/assets/icons/sun.svg?react";
import MoonIcon from "@/assets/icons/moon.svg?react";

interface ThemeTogglerProps {
  expanded: boolean;
}

const ThemeToggler = ({ expanded }: ThemeTogglerProps) => {
  const theme = useAppSelector(themeSelector);
  const dispatch = useAppDispatch();

  const handleToggleTheme = () => {
    dispatch({
      type: "app/toggleTheme",
      payload:
        (theme === "system" &&
          window.matchMedia("(prefers-color-scheme: dark)").matches) ||
        theme === "dark"
          ? "light"
          : "dark",
    });
  };

  return (
    <div className="flex items-center justify-between">
      {expanded && (
        <div className="text-sm text-tertiary flex items-center gap-[12px] transition-colors duration-200 py-[10px]">
          {(theme === "system" &&
            window.matchMedia("(prefers-color-scheme: dark)").matches) ||
          theme === "dark" ? (
            <MoonIcon className="size-[20px]" />
          ) : (
            <SunIcon className="size-[20px]" />
          )}
          Theme
        </div>
      )}
      <Switch
        checked={
          (theme === "system" &&
            window.matchMedia("(prefers-color-scheme: dark)").matches) ||
          theme === "dark"
            ? false
            : true
        }
        onChange={() => handleToggleTheme()}
        className={cn(
          "relative inline-flex w-[45px] h-[25.71px] items-center rounded-full bg-accent-blue dark:bg-accent-main shadow-[inset_0px_4px_4px_0px_rgba(26,_25,_25,_0.15)]"
        )}
      >
        {({ checked }) => (
          <>
            <span className="sr-only">
              Switch to{" "}
              {(theme === "system" &&
                window.matchMedia("(prefers-color-scheme: dark)").matches) ||
              theme === "dark"
                ? "light"
                : "dark"}{" "}
              mode
            </span>
            <span
              className={cn(
                "inline-block size-[22.5px] transform rounded-full bg-white dark:bg-[#232323] transition",
                checked ? "translate-x-[20.61px]" : "translate-x-[1.61px]"
              )}
            />
          </>
        )}
      </Switch>
    </div>
  );
};

export default ThemeToggler;
