import { useEffect, useRef } from "react";
import { cn } from "@/utils/utils";

import ThumbnailPlaceholder from "@/assets/icons/placeholders/image.svg?react";

interface ThumbnailProps {
  className?: string;
  small?: boolean;
  src?: string;
  isAiGenerated?: boolean;
}

const Thumbnail = ({
  className,
  small,
  src,
  isAiGenerated,
}: ThumbnailProps) => {
  const imgRef = useRef<HTMLImageElement>(null);

  const handleRemoveImg = () => {
    if (imgRef.current) {
      imgRef.current.src = "";
      imgRef.current.remove();
    }
  };

  useEffect(() => {
    const urlHasPrefix = /^[a-zA-Z][a-zA-Z\d+\-.]*:/.test(src ?? "");

    if (imgRef.current) {
      if (src && urlHasPrefix) {
        imgRef.current.src = src;
      } else {
        imgRef.current.src = `${import.meta.env.VITE_THUMBNAIL_HOST}/${
          isAiGenerated ? "AI/" : ""
        }${src}`;
      }
    }
  }, [isAiGenerated, src]);

  return (
    <div
      className={cn(
        "bg-[#ECECEC] dark:bg-tertiary-dark-gray flex items-center justify-center w-full overflow-hidden relative z-0",
        small ? "flex-1 min-h-[auto]" : "h-full",
        className
      )}
    >
      <img
        ref={imgRef}
        alt=""
        className="absolute inset-0 object-cover z-0 size-full pointer-events-none"
        onError={(e) => handleRemoveImg()}
      />
      <ThumbnailPlaceholder
        className={cn(
          "relative -z-10",
          small
            ? "size-[36px] md:size-[56.12px]"
            : "size-[62px] md:size-[87.69px]"
        )}
      />
    </div>
  );
};

export default Thumbnail;
