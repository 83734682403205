import { userSelector } from "@/redux/App";
import { useAppSelector } from "@/redux/hooks";
import { User } from "@/shared/types/user";
import { cn } from "@/utils/utils";
import { Link, Outlet, useLocation, useOutletContext } from "react-router";

const SettingsLayout = () => {
  const { pathname } = useLocation();
  const userId = useAppSelector(userSelector)?.id;
  const { user } = useOutletContext<{ user: User }>();

  return (
    <>
      <div className="flex items-center justify-center md:justify-between w-full border-b-[1px] border-[#EFEFEF] dark:border-[#313131] mb-[30px] md:mb-[32px] max-md:px-[33px] md:mt-[49px]">
        <nav className="flex items-center gap-[24px] overflow-x-auto whitespace-nowrap scrollbar-hide">
          <Tab
            href="/creator-dashboard/settings"
            active={pathname === "/creator-dashboard/settings"}
          >
            Personal information
          </Tab>
          <Tab
            href="/creator-dashboard/settings/security"
            active={pathname === "/creator-dashboard/settings/security"}
          >
            Security
          </Tab>
          <Tab
            href="/creator-dashboard/settings/kyc"
            active={pathname === "/creator-dashboard/settings/kyc"}
          >
            KYC
          </Tab>
        </nav>
        <Link
          to={`/user/${user?.userName || userId}`}
          className="font-medium text-sm tracking-[-0.28px] max-md:hidden"
        >
          View my profile
        </Link>
      </div>
      <div className="max-md:px-[20px]">
        <Outlet />
      </div>
    </>
  );
};

const Tab = ({
  active,
  children,
  href,
}: {
  href: string;
  active: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Link
      to={href}
      className={cn(
        "pt-[8px] pb-[14px] border-b-[1px] unstyled-link max-md:text-sm shrink-0",
        active
          ? "font-medium text-black dark:text-white border-black dark:border-white"
          : "text-tertiary border-transparent"
      )}
    >
      {children}
    </Link>
  );
};

export default SettingsLayout;
