import { cn } from "@/utils/utils";
import { Checkbox as HUICheckbox, Field, Label } from "@headlessui/react";
import { forwardRef } from "react";
import { FieldError } from "react-hook-form";
import { FaCheck } from "react-icons/fa6";

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  fieldClassName?: string;
  className?: string;
  label?: string | React.ReactNode;
  labelClassName?: string;
  checkClassName?: string;
  onChange?: any;
  error?: FieldError | string;
}

const Checkbox = forwardRef(
  (
    {
      fieldClassName,
      className,
      label,
      labelClassName,
      checkClassName,
      onChange,
      error,
      ...props
    }: CheckboxProps,
    ref
  ) => (
    <Field
      className={cn("flex items-start md:items-center gap-2", fieldClassName)}
    >
      <HUICheckbox
        className={cn(
          "group size-[16px] rounded border border-tertiary-gray bg-white dark:bg-transparent data-[checked]:bg-accent-blue dark:data-[checked]:bg-accent-main data-[checked]:border-accent-blue dark:data-[checked]:border-accent-main flex items-center justify-center shrink-0",
          className
        )}
        {...props}
        onChange={(checked) => {
          onChange?.({ target: { value: checked, checked } });
        }}
      >
        <FaCheck
          className={cn(
            "text-white dark:text-black size-[0.563rem] opacity-0 group-data-[checked]:opacity-100",
            checkClassName
          )}
        />
      </HUICheckbox>
      {label && (
        <Label
          className={cn("text-xs text-tertiary", labelClassName, {
            "text-error-default dark:text-error-dark-default font-medium":
              error,
          })}
        >
          {label}
        </Label>
      )}
    </Field>
  )
);

export default Checkbox;
