import {
  Outlet,
  ScrollRestoration,
  useLocation,
  useNavigation,
} from "react-router";
import UploadQueue from "@/features/UploadQueue";
import AudioQueue from "@/features/AudioQueue";
import BulkUploadPopup from "@/features/BulkUpload";
import PageLoader from "@/components/PageLoader";
import LogoutModal from "@/components/Header/UserDropdown/LogoutModal";

const RootLayout = () => {
  const location = useLocation();
  const navigation = useNavigation();

  return (
    <>
      <ScrollRestoration getKey={(location) => location.pathname} />
      {navigation.state === "loading" && <PageLoader />}
      {location.pathname !== "/upload" && <UploadQueue />}
      <LogoutModal />
      <AudioQueue />
      <BulkUploadPopup />
      <Outlet />
    </>
  );
};

export default RootLayout;
