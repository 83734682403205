import { RootState } from "redux/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Cookies } from "react-cookie";
import { Currency } from "@/shared/types/currencies";
import { UserInfo, UserRoles } from "@/shared/types/user";

const cookies = new Cookies();

interface AppState {
  theme: string | null;
  locale: string | null;
  currency: Currency | null;
  wideLayout?: boolean;
  bulkUploadPopup?: boolean;
  logoutPopup?: boolean;
  expandedSidebar?: boolean;
  user: {
    token: string | null;
    refreshToken: string | null;
    id: string | null;
    info: UserInfo;
  };
}

const initialState: AppState = {
  theme:
    "mo7tawa-theme" in localStorage
      ? localStorage.getItem("mo7tawa-theme")
      : "system",
  currency: null,
  wideLayout: "mo7tawa-compact-layout" in localStorage ? false : true,
  locale:
    "mo7tawa-locale" in localStorage
      ? localStorage.getItem("mo7tawa-locale")
      : "en",
  expandedSidebar: "expanded-sidebar" in localStorage ? true : false,
  user: {
    token: cookies.get("mo7tawa-token") ?? null,
    refreshToken: cookies.get("mo7tawa-refresh-token") ?? null,
    id: null,
    info: {
      email: null,
      fullName: null,
      firstName: null,
      bankSetup: false,
      categorySetup: false,
      profilePicture: null,
      roles: [],
      coverPhoto: null,
      userName: null,
    },
  },
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    toggleTheme: (
      state,
      action: PayloadAction<"light" | "dark" | "system">
    ) => {
      const theme = action.payload;
      state.theme = theme;
      localStorage.setItem("mo7tawa-theme", theme);
    },
    toggleSidebar: (state) => {
      if (state.expandedSidebar) {
        state.expandedSidebar = false;
        localStorage.removeItem("expanded-sidebar");
      } else {
        state.expandedSidebar = true;
        localStorage.setItem("expanded-sidebar", "true");
      }
    },
    toggleLanguage: (state, action: PayloadAction<"en" | "ar">) => {
      const locale = action.payload;
      state.locale = locale;
      localStorage.setItem("mo7tawa-locale", locale);
    },
    toggleWideLayout: (state) => {
      if (state.wideLayout) {
        state.wideLayout = false;
        localStorage.setItem("mo7tawa-compact-layout", "true");
      } else {
        state.wideLayout = true;
        localStorage.removeItem("mo7tawa-compact-layout");
      }
    },
    setCurrency: (state, action: PayloadAction<Currency>) => {
      const currency = action.payload;
      state.currency = currency;
    },
    login: (
      state,
      action: PayloadAction<{
        token: string;
        email: string;
        refreshToken: string;
      }>
    ) => {
      const { token, email, refreshToken } = action.payload;
      state.user.token = token;
      state.user.refreshToken = refreshToken;
      state.user.info.email = email;
    },
    updateToken: (
      state,
      action: PayloadAction<{ token: string; refreshToken: string }>
    ) => {
      state.user.token = action.payload.token;
      state.user.refreshToken = action.payload.refreshToken;
    },
    updateUserInfo: (
      state,
      action: PayloadAction<{
        id: string;
        info: UserInfo;
      }>
    ) => {
      const { id, info } = action.payload;
      state.user.id = id;
      state.user.info = info;
    },
    updateLocalPfp: (state, action: PayloadAction<string>) => {
      state.user.info.profilePicture = action.payload;
    },
    setRole: (state, action: PayloadAction<UserRoles>) => {
      state.user.info.roles?.push(action.payload);
    },
    completeBankSetup: (state) => {
      state.user.info.bankSetup = true;
    },
    completeCategorySetup: (state) => {
      state.user.info.categorySetup = true;
      state.user.info.roles?.push("Creator");
    },
    completeCompanySetup: (state) => {
      state.user.info.companySetup = true;
    },
    logout: (state) => {
      cookies.remove("mo7tawa-token", {
        path: "/",
      });
      cookies.remove("mo7tawa-token-expires-at", {
        path: "/",
      });
      cookies.remove("mo7tawa-refresh-token", {
        path: "/",
      });
      state.user.id = null;
      state.user.token = null;
      state.user.refreshToken = null;
      state.user.info = initialState.user.info;
    },
    showBulkUploadPopup: (state, action: PayloadAction<boolean>) => {
      state.bulkUploadPopup = action.payload;
    },
    showLogoutPopup: (state, action: PayloadAction<boolean>) => {
      state.logoutPopup = action.payload;
    },
  },
});

export const {
  toggleTheme,
  login,
  toggleWideLayout,
  updateToken,
  updateUserInfo,
  logout,
  completeBankSetup,
  completeCategorySetup,
  updateLocalPfp,
  setCurrency,
  setRole,
  showBulkUploadPopup,
  completeCompanySetup,
  showLogoutPopup,
  toggleSidebar,
} = appSlice.actions;

export const themeSelector = (state: RootState) => state.appSlice.theme;
export const isDarkModeSelector = (state: RootState) => {
  const theme = state.appSlice.theme;
  return (
    (theme === "system" &&
      window.matchMedia("(prefers-color-scheme: dark)").matches) ||
    theme === "dark"
  );
};
export const languageSelector = (state: RootState) => state.appSlice.locale;
export const wideLayoutSelector = (state: RootState) =>
  state.appSlice.wideLayout;
export const userSelector = (state: RootState) => state.appSlice.user;
export const userInfoSelector = (state: RootState) => state.appSlice.user.info;
export const isCreatorSelector = (state: RootState) =>
  state.appSlice.user.info.categorySetup;
export const isLoggedInSelector = (state: RootState) =>
  Boolean(state.appSlice.user.token);
export const userRolesSelector = (state: RootState) =>
  state.appSlice.user.info.roles;
export const currencySelector = (state: RootState) => state.appSlice.currency;
export const bulkUploadPopupSelector = (state: RootState) =>
  state.appSlice.bulkUploadPopup;
export const logoutPopupSelector = (state: RootState) =>
  state.appSlice.logoutPopup;
export const companySetupSelector = (state: RootState) =>
  state.appSlice.user.info.companySetup;
export const companyIdSelector = (state: RootState) =>
  state.appSlice.user.info.companyId;
export const userInCompanySelector = (state: RootState) =>
  Boolean(state.appSlice.user.info.companyId);
export const isCompanyAdminSelector = (state: RootState) =>
  state.appSlice.user.info.isAdmin;
export const isSidebarExpandedSelector = (state: RootState) =>
  Boolean(state.appSlice.expandedSidebar);

export default appSlice.reducer;
