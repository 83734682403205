import { Link, useLocation } from "react-router";
import { MenuLink } from "./Menu";
import { useAppSelector } from "@/redux/hooks";
import { isCreatorSelector } from "@/redux/App";
import Avatar from "./Avatar";
import Divider from "@/components/Divider";

import ArrowIcon from "@/assets/icons/arrows/arrow1.svg?react";
import WandIcon from "@/assets/icons/creator_dashboard/wand.svg?react";
import CreatedIcon from "@/assets/icons/creator_dashboard/created.svg?react";
import FavoritesIcon from "@/assets/icons/hearts/heart_outline.svg?react";
import CollectionIcon from "@/assets/icons/creator_dashboard/collection.svg?react";
import SettingsIcon from "@/assets/icons/creator_dashboard/cog.svg?react";

interface MobileTopProps {
  userId: string;
  username?: string | null;
  name?: string | null;
  pfp?: string | null;
}

const MobileTop = ({ userId, username, name, pfp }: MobileTopProps) => {
  const { pathname } = useLocation();
  const isCreator = useAppSelector(isCreatorSelector);

  return (
    <section>
      <div className="relative h-[116px] bg-[#EFF6FF] dark:bg-[#232323] mb-[48px]">
        <div className="p-[20px] flex items-center justify-between">
          <Link
            to="/"
            className="text-tertiary unstyled-link"
            title="Back to Home"
          >
            <ArrowIcon className="size-[20px]" />
          </Link>
          <Link to={`/user/${userId}`} className="text-sm -tracking-[2%]">
            Profile
          </Link>
        </div>
        <Avatar
          className="absolute left-1/2 -translate-x-1/2 -bottom-[38px]"
          avatarClassName="size-[104px] border-[3px] shadow-[0px_4px_100px_0px_rgba(65,_143,_232,_0.25)] dark:shadow-[0px_4px_100px_0px_rgba(195,_245,_0,_0.25)]"
          bigPen
          src={pfp}
        />
      </div>
      <div className="text-center px-[20px]">
        <h3 className="font-medium text-[24px] leading-[36px] truncate">
          {name}
        </h3>
        {username && (
          <p className="text-tertiary font-medium text-sm truncate">
            @{username}
          </p>
        )}
      </div>
      <Divider
        gradient
        className="max-w-[250px] opacity-60 mt-[52px] mb-[17px]"
      />
      <nav className="px-[18px] border-b-[1px] border-[#EFEFEF] dark:border-[#313131] flex items-center justify-center">
        <ul className="flex items-center gap-[21px] overflow-x-auto scrollbar-none whitespace-nowrap">
          <MenuLink
            icon={WandIcon}
            href="/creator-dashboard/purchases"
            active={pathname === "/creator-dashboard/purchases"}
            className="py-[14.59px] gap-[8px]"
            withBorder
            expanded
          >
            Purchased
          </MenuLink>
          {isCreator && (
            <MenuLink
              icon={CreatedIcon}
              href="/creator-dashboard"
              active={pathname === "/creator-dashboard"}
              className="py-[14.59px] gap-[8px]"
              withBorder
              expanded
            >
              Created
            </MenuLink>
          )}
          <MenuLink
            icon={CollectionIcon}
            href="/creator-dashboard/collections"
            active={pathname === "/creator-dashboard/collections"}
            className="py-[14.59px] gap-[8px]"
            withBorder
            expanded
          >
            Collections
          </MenuLink>
          <MenuLink
            icon={FavoritesIcon}
            href="/creator-dashboard/favorites"
            active={pathname === "/creator-dashboard/favorites"}
            className="py-[14.59px] gap-[8px]"
            withBorder
            expanded
          >
            Favorites
          </MenuLink>
          <MenuLink
            icon={SettingsIcon}
            href="/creator-dashboard/settings"
            active={pathname === "/creator-dashboard/settings"}
            className="py-[14.59px] gap-[8px]"
            withBorder
            expanded
          >
            Settings
          </MenuLink>
        </ul>
      </nav>
    </section>
  );
};

export default MobileTop;
