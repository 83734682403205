import { useAppSelector } from "@/redux/hooks";
import { minimizedAudioSelector } from "@/redux/Product";
import { AnimatePresence, motion } from "framer-motion";
import AudioPlayer from "./AudioPlayer";

const AudioQueue = () => {
  const minimizedPlayer = useAppSelector(minimizedAudioSelector);

  return (
    <AnimatePresence>
      {minimizedPlayer && (
        <motion.div
          className="fixed bottom-0 z-30 w-full h-[105px] lg:h-[108px]"
          initial={{ y: "100%" }}
          animate={{ y: 0 }}
          exit={{ y: "100%" }}
          transition={{ type: "tween" }}
        >
          <AudioPlayer
            src={minimizedPlayer.trackUrl}
            variant="minimized"
            startTime={minimizedPlayer.startAt}
            miniPlayer
            trackName={minimizedPlayer.trackName}
            author={{
              id: minimizedPlayer?.authorId ?? "",
              name: minimizedPlayer?.authorName ?? "",
            }}
            productId={minimizedPlayer.productId}
            thumbnail={minimizedPlayer.thumbnail}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AudioQueue;
